import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { rapidForm } from '@truto/truto-link-sdk';
import { Button } from '@mantine/core';
import { toast } from 'react-toastify';
import { useLazyGetTrutoIntegratedAccountTokenQuery } from 'redux/services/truto';
import integrationsApi, { useAddSourcesMutation } from 'redux/services/integrations';
import { TRUTO_CONNECTIONS_WITH_ADD_SOURCE, TRUTO_CONNECTORS_NAME_MAPPING } from 'utils/constants';
import { getTrutoAddSourcePayload } from 'pages/Integrations/utils';

const AddAPIDeckSources = ({ provider, teamIdFromProps, clickCallback }) => {
  const [getTrutoIntegratedAccountToken] = useLazyGetTrutoIntegratedAccountTokenQuery();
  const dispatch = useDispatch();
  const [addSources, { isLoading }] = useAddSourcesMutation();
  const { user } = useSelector((state) => state.user);
  const { teamId } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleIntegrateClick = async () => {
    setIsDisabled(true);
    try {
      if (TRUTO_CONNECTIONS_WITH_ADD_SOURCE.includes(provider)) {
        const { data } = await getTrutoIntegratedAccountToken(provider);
        const response = await rapidForm(data.token);

        if (response.result === 'success') {
          const rapidFormResponse = JSON.parse(response.post_connect_form || {});
          if (!rapidFormResponse.collections && !rapidFormResponse.drive_items) {
            toast.error('Please select at least one source to add');
            setIsDisabled(false);
            return;
          }

          const payload = getTrutoAddSourcePayload({
            response: rapidFormResponse,
            provider,
            user
          });

          if (payload.length) {
            const { data } = await addSources({
              body: { documents: payload },
              provider,
              params: { teamId: teamId || teamIdFromProps }
            });
            if (data?.ok) {
              clickCallback?.();
            }
            dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));
          } else {
            toast.error('Please select at least one source to add');
          }
        }
      } else {
        const payload = {
          provider: provider,
          fileType: 'txt',
          documentId: `${provider}_${user._id}`,
          documentName: `${user.name}'s ${TRUTO_CONNECTORS_NAME_MAPPING[provider]}`
        };
        await addSources({
          body: { documents: [payload] },
          params: { teamId: teamId || teamIdFromProps },
          provider
        });
        clickCallback?.();
      }
      setIsDisabled(false);
    } catch (error) {
      setIsDisabled(false);
      toast.error('Oops, something went wrong. Try again!');
    }
  };

  return (
    <Button
      className='primaryBtn'
      onClick={(e) => {
        e.stopPropagation();
        handleIntegrateClick();
      }}
      loading={isLoading}
      disabled={isDisabled}
    >
      Add source
    </Button>
  );
};

AddAPIDeckSources.defaultProps = {
  clickCallback: () => null,
  teamIdFromProps: ''
};

AddAPIDeckSources.propTypes = {
  provider: PropTypes.string.isRequired,
  clickCallback: PropTypes.func,
  teamIdFromProps: PropTypes.string
};

export default AddAPIDeckSources;
